<template>
  <div class="home">
    <!-- <el-carousel arrow="never">
      <el-carousel-item
        v-for="(item, index) in (screenWidth > 850 && carouselPCArr) ||
        carouselMobileArr"
        :key="index"
      >
        <img :src="`/api/ffs/file/${item}`" class="bannerImg" />
      </el-carousel-item>
    </el-carousel> -->
    <div ref="mySwiper" class="swiper-container" id="mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in (screenWidth > 850 && carouselPCArr) ||
        carouselMobileArr" :key="index">
          <img :src="`/api/ffs/file/${item}`" class="bannerImg" />
       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  computed: {
    ...mapGetters(["screenWidth"]),
  },
  data() {
    return {
      photoArray: [1, 2, 3, 4, 5], //轮播图数组
      carouselPCArr: [],
      carouselMobileArr: [],
    };
  },

  methods: {
    // 获取轮播图片数组
    getBannerData(info) {
      this.carouselPCArr = info.carouselPC.map((item) => item.id);
      this.carouselMobileArr = info.carouselMobile.map((item) => item.id);
      // info.carouselPC.map((item) => {
      //   debugger;
      //   this.carouselPCArr.push(carouselUrl);
      //   // console.log(JSON.parse(JSON.stringify(this.carouselPCArr)));
      // });
      // info.carouselMobile.map((item) => {
      //   this.carouselMobileArr.push(carouselUrl);
      // });
    },

    //获取轮播id数组
    getData() {
      request({
        url: "/api/ui/homepage/carousel",
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.getBannerData(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //初始化swiper
    initSwiper() {
      // const perView=this.screenWidth>850&&4
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      this.currentSwiper = new Swiper("#mySwiper", {
        autoplay:true,
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        slidesPerView: 1, //视口展示slide数1
      });
    },
    //销毁swiper
    destroySwiper() {
      try {
        this.currentSwiper.destroy(true, true);
      } catch (e) {
        console.log("删除轮播");
      }
    },
    //更新swiper
    updateSwiper() {
      this.destroySwiper();
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  mounted() {
    this.getData();
    setTimeout(() => {
      this.initSwiper();
    }, 1000);
  },
  unmounted() { },
};
</script>


<style  scoped>
.home {
  height: inherit;
  width: 100%;
}

/deep/ .el-carousel__container {
  min-width: 600px;
  min-height: 700px;
}

.bannerImg {
  height: 100vh;
 object-fit: none;
}

/* 走马灯位置设置 */
/deep/ .el-carousel__indicators--horizontal {
  left: auto;
  right: 0;
  bottom: 70px;
}

/* 走马灯样式设置 */
/deep/.el-carousel__indicator {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  padding: 0;
  overflow: hidden;
  border: 1px solid white;
  box-sizing: border-box;
  margin-right: 20px;
  background: white;
}

/deep/ .el-carousel__indicator--horizontal.is-active {
  background: none;
}

/deep/.el-carousel__button {
  width: 0;
}
</style>>
